/* eslint-disable @typescript-eslint/no-empty-function */

import {
  $,
  isCssPropsFromAxes,
  setCssProps,
  revertCssProps,
  useDirection,
  getDirection,
} from "../utils";
import {
  IS_IOS_SAFARI,
  IOS_EDGE_THRESHOLD,
  DIRECTION_NONE,
  DIRECTION_VERTICAL,
  DIRECTION_HORIZONTAL,
  MOUSE_LEFT,
} from "../const";
import { ActiveEvent, InputEventType } from "../types";

import {
  convertInputType,
  InputType,
  InputTypeObserver,
  toAxis,
} from "./InputType";

export interface PanInputOption {
  inputType?: string[];
  inputButton?: string[];
  scale?: number[];
  thresholdAngle?: number;
  threshold?: number;
  iOSEdgeSwipeThreshold?: number;
  releaseOnScroll?: boolean;
  touchAction?: string;
}

// get user's direction
export const getDirectionByAngle = (
  angle: number,
  thresholdAngle: number
): number => {
  if (thresholdAngle < 0 || thresholdAngle > 90) {
    return DIRECTION_NONE;
  }
  const toAngle = Math.abs(angle);

  return toAngle > thresholdAngle && toAngle < 180 - thresholdAngle
    ? DIRECTION_VERTICAL
    : DIRECTION_HORIZONTAL;
};

/**
 * @typedef {Object} PanInputOption The option object of the eg.Axes.PanInput module.
 * @ko eg.Axes.PanInput 모듈의 옵션 객체
 * @param {String[]} [inputType=["touch", "mouse", "pointer"]] Types of input devices
 * - touch: Touch screen
 * - mouse: Mouse
 * - pointer: Mouse and touch <ko>입력 장치 종류
 * - touch: 터치 입력 장치
 * - mouse: 마우스
 * - pointer: 마우스 및 터치</ko>
 * @param {String[]} [inputButton=["left"]] List of buttons to allow input
 * - left: Left mouse button and normal touch
 * - middle: Mouse wheel press
 * - right: Right mouse button <ko>입력을 허용할 버튼 목록
 * - left: 마우스 왼쪽 버튼
 * - middle: 마우스 휠 눌림
 * - right: 마우스 오른쪽 버튼 </ko>
 * @param {Number[]} [scale] Coordinate scale that a user can move<ko>사용자의 동작으로 이동하는 좌표의 배율</ko>
 * @param {Number} [scale[0]=1] horizontal axis scale <ko>수평축 배율</ko>
 * @param {Number} [scale[1]=1] vertical axis scale <ko>수직축 배율</ko>
 * @param {Number} [thresholdAngle=45] The threshold value that determines whether user action is horizontal or vertical (0~90) <ko>사용자의 동작이 가로 방향인지 세로 방향인지 판단하는 기준 각도(0~90)</ko>
 * @param {Number} [threshold=0] Minimal pan distance required before recognizing <ko>사용자의 Pan 동작을 인식하기 위해산 최소한의 거리</ko>
 * @param {Number} [iOSEdgeSwipeThreshold=30] Area (px) that can go to the next page when swiping the right edge in iOS safari <ko>iOS Safari에서 오른쪽 엣지를 스와이프 하는 경우 다음 페이지로 넘어갈 수 있는 영역(px)</ko>
 * @param {String} [touchAction=null] Value that overrides the element's "touch-action" css property. If set to null, it is automatically set to prevent scrolling in the direction of the connected axis. <ko>엘리먼트의 "touch-action" CSS 속성을 덮어쓰는 값. 만약 null로 설정된 경우, 연결된 축 방향으로의 스크롤을 방지하게끔 자동으로 설정된다.</ko>
 **/
/**
 * A module that passes the amount of change to eg.Axes when the mouse or touchscreen is down and moved. use less than two axes.
 * @ko 마우스나 터치 스크린을 누르고 움직일때의 변화량을 eg.Axes에 전달하는 모듈. 두개 이하의 축을 사용한다.
 *
 * @example
 * ```js
 * const pan = new eg.Axes.PanInput("#area", {
 * 		inputType: ["touch"],
 * 		scale: [1, 1.3],
 * });
 *
 * // Connect the 'something2' axis to the mouse or touchscreen x position when the mouse or touchscreen is down and moved.
 * // Connect the 'somethingN' axis to the mouse or touchscreen y position when the mouse or touchscreen is down and moved.
 * axes.connect(["something2", "somethingN"], pan); // or axes.connect("something2 somethingN", pan);
 *
 * // Connect only one 'something1' axis to the mouse or touchscreen x position when the mouse or touchscreen is down and moved.
 * axes.connect(["something1"], pan); // or axes.connect("something1", pan);
 *
 * // Connect only one 'something2' axis to the mouse or touchscreen y position when the mouse or touchscreen is down and moved.
 * axes.connect(["", "something2"], pan); // or axes.connect(" something2", pan);
 * ```
 * @param {HTMLElement|String|jQuery} element An element to use the eg.Axes.PanInput module <ko>eg.Axes.PanInput 모듈을 사용할 엘리먼트</ko>
 * @param {PanInputOption} [options={}] The option object of the eg.Axes.PanInput module<ko>eg.Axes.PanInput 모듈의 옵션 객체</ko>
 */
export class PanInput implements InputType {
  public options: PanInputOption;
  public axes: string[] = [];
  public element: HTMLElement = null;
  protected _observer: InputTypeObserver;
  protected _direction: number;
  protected _enabled = false;
  protected _activeEvent: ActiveEvent = null;
  private _originalCssProps: { [key: string]: string };
  private _atRightEdge = false;
  private _rightEdgeTimer = 0;

  /**
   *
   */
  public constructor(el: string | HTMLElement, options?: PanInputOption) {
    this.element = $(el);
    this.options = {
      inputType: ["touch", "mouse", "pointer"],
      inputButton: [MOUSE_LEFT],
      scale: [1, 1],
      thresholdAngle: 45,
      threshold: 0,
      iOSEdgeSwipeThreshold: IOS_EDGE_THRESHOLD,
      releaseOnScroll: false,
      touchAction: null,
      ...options,
    };
    this._onPanstart = this._onPanstart.bind(this);
    this._onPanmove = this._onPanmove.bind(this);
    this._onPanend = this._onPanend.bind(this);
  }

  public mapAxes(axes: string[]) {
    this._direction = getDirection(!!axes[0], !!axes[1]);
    this.axes = axes;
  }

  public connect(observer: InputTypeObserver): InputType {
    if (this._activeEvent) {
      this._detachElementEvent();
      this._detachWindowEvent(this._activeEvent);
    }
    this._attachElementEvent(observer);
    this._originalCssProps = setCssProps(
      this.element,
      this.options,
      this._direction
    );
    return this;
  }

  public disconnect() {
    this._detachElementEvent();
    this._detachWindowEvent(this._activeEvent);
    if (!isCssPropsFromAxes(this._originalCssProps)) {
      revertCssProps(this.element, this._originalCssProps);
    }
    this._direction = DIRECTION_NONE;
    return this;
  }

  /**
   * Destroys elements, properties, and events used in a module.
   * @ko 모듈에 사용한 엘리먼트와 속성, 이벤트를 해제한다.
   */
  public destroy() {
    this.disconnect();
    this.element = null;
  }

  /**
   * Enables input devices
   * @ko 입력 장치를 사용할 수 있게 한다
   * @return {PanInput} An instance of a module itself <ko>모듈 자신의 인스턴스</ko>
   */
  public enable() {
    this._enabled = true;
    return this;
  }

  /**
   * Disables input devices
   * @ko 입력 장치를 사용할 수 없게 한다.
   * @return {PanInput} An instance of a module itself <ko>모듈 자신의 인스턴스</ko>
   */
  public disable() {
    this._enabled = false;
    return this;
  }

  /**
   * Returns whether to use an input device
   * @ko 입력 장치를 사용 여부를 반환한다.
   * @return {Boolean} Whether to use an input device <ko>입력장치 사용여부</ko>
   */
  public isEnabled() {
    return this._enabled;
  }

  protected _onPanstart(event: InputEventType) {
    const activeEvent = this._activeEvent;
    const panEvent = activeEvent.onEventStart(event, this.options.inputButton);
    if (!panEvent || !this._enabled || activeEvent.getTouches(event) > 1) {
      return;
    }

    if (panEvent.srcEvent.cancelable !== false) {
      const edgeThreshold = this.options.iOSEdgeSwipeThreshold;

      this._observer.hold(this, panEvent);
      this._atRightEdge =
        IS_IOS_SAFARI && panEvent.center.x > window.innerWidth - edgeThreshold;
      this._attachWindowEvent(activeEvent);
      activeEvent.prevEvent = panEvent;
    }
  }

  protected _onPanmove(event: InputEventType) {
    const activeEvent = this._activeEvent;
    const panEvent = activeEvent.onEventMove(event, this.options.inputButton);
    if (!panEvent || !this._enabled || activeEvent.getTouches(event) > 1) {
      return;
    }

    const { iOSEdgeSwipeThreshold, releaseOnScroll } = this.options;
    const userDirection = getDirectionByAngle(
      panEvent.angle,
      this.options.thresholdAngle
    );

    if (releaseOnScroll && !panEvent.srcEvent.cancelable) {
      this._onPanend(event);
      return;
    }

    if (activeEvent.prevEvent && IS_IOS_SAFARI) {
      const swipeLeftToRight = panEvent.center.x < 0;

      if (swipeLeftToRight) {
        // iOS swipe left => right
        this._forceRelease();
        return;
      } else if (this._atRightEdge) {
        clearTimeout(this._rightEdgeTimer);

        // - is right to left
        const swipeRightToLeft = panEvent.deltaX < -iOSEdgeSwipeThreshold;

        if (swipeRightToLeft) {
          this._atRightEdge = false;
        } else {
          // iOS swipe right => left
          this._rightEdgeTimer = window.setTimeout(
            () => this._forceRelease(),
            100
          );
        }
      }
    }
    const offset = this._getOffset(
      [panEvent.offsetX, panEvent.offsetY],
      [
        useDirection(DIRECTION_HORIZONTAL, this._direction, userDirection),
        useDirection(DIRECTION_VERTICAL, this._direction, userDirection),
      ]
    );
    const prevent = offset.some((v) => v !== 0);

    if (prevent) {
      if (panEvent.srcEvent.cancelable !== false) {
        panEvent.srcEvent.preventDefault();
      }
      panEvent.srcEvent.stopPropagation();
    }
    panEvent.preventSystemEvent = prevent;
    if (prevent) {
      this._observer.change(this, panEvent, toAxis(this.axes, offset));
    }
    activeEvent.prevEvent = panEvent;
  }

  protected _onPanend(event: InputEventType) {
    const activeEvent = this._activeEvent;
    activeEvent.onEventEnd(event);
    if (!this._enabled || activeEvent.getTouches(event) !== 0) {
      return;
    }
    this._detachWindowEvent(activeEvent);
    clearTimeout(this._rightEdgeTimer);
    const prevEvent = activeEvent.prevEvent;
    const velocity = this._getOffset(
      [
        Math.abs(prevEvent.velocityX) * (prevEvent.offsetX < 0 ? -1 : 1),
        Math.abs(prevEvent.velocityY) * (prevEvent.offsetY < 0 ? -1 : 1),
      ],
      [
        useDirection(DIRECTION_HORIZONTAL, this._direction),
        useDirection(DIRECTION_VERTICAL, this._direction),
      ]
    );
    activeEvent.onRelease();
    this._observer.release(this, prevEvent, velocity);
  }

  protected _attachWindowEvent(activeEvent: ActiveEvent) {
    activeEvent?.move.forEach((event) => {
      window.addEventListener(event, this._onPanmove, { passive: false });
    });
    activeEvent?.end.forEach((event) => {
      window.addEventListener(event, this._onPanend, { passive: false });
    });
  }

  protected _detachWindowEvent(activeEvent: ActiveEvent) {
    activeEvent?.move.forEach((event) => {
      window.removeEventListener(event, this._onPanmove);
    });
    activeEvent?.end.forEach((event) => {
      window.removeEventListener(event, this._onPanend);
    });
  }

  protected _getOffset(properties: number[], direction: boolean[]): number[] {
    const scale = this.options.scale;
    return [
      direction[0] ? properties[0] * scale[0] : 0,
      direction[1] ? properties[1] * scale[1] : 0,
    ];
  }

  private _attachElementEvent(observer: InputTypeObserver) {
    const activeEvent = convertInputType(this.options.inputType);
    if (!activeEvent) {
      return;
    }
    this._observer = observer;
    this._enabled = true;
    this._activeEvent = activeEvent;
    activeEvent.start.forEach((event) => {
      this.element?.addEventListener(event, this._onPanstart);
    });
    // adding event listener to element prevents invalid behavior in iOS Safari
    activeEvent.move.forEach((event) => {
      this.element?.addEventListener(event, this._voidFunction);
    });
  }

  private _detachElementEvent() {
    const activeEvent = this._activeEvent;
    activeEvent?.start.forEach((event) => {
      this.element?.removeEventListener(event, this._onPanstart);
    });
    activeEvent?.move.forEach((event) => {
      this.element?.removeEventListener(event, this._voidFunction);
    });
    this._enabled = false;
    this._observer = null;
  }

  private _forceRelease = () => {
    const activeEvent = this._activeEvent;
    const prevEvent = activeEvent.prevEvent;
    activeEvent.onRelease();
    this._observer.release(this, prevEvent, [0, 0]);
    this._detachWindowEvent(activeEvent);
  };

  private _voidFunction = () => {};
}
